import React from "react"
// import { Link } from "gatsby"
// import classNames from "classnames"

import Layout from "../components/layout"
// import Image from "../components/image"
import SEO from "../components/seo"
import data from "../json/fqa.json"
import st from "./css/fqa.module.scss"

const FqaPage = function ({ isMobile }) {
  if (typeof document !== "undefined") {
    isMobile = document.documentElement.clientWidth <= 1140
  }
  return (
    <Layout cur="/fqa/" stickyHeader>
      <SEO
        title="酷课约课系统常见问答"
        keywords={["帮助文档", "酷课帮助", "常见问题", "酷课问答"]}
        description="关于酷课约课软件的常见问题，包括售前、售后使用上的各种问题解答。"
      />

      <div className={st.wrapper}>
        <div className={st.content}>
          {data.map((v, i) => (
            <section className={st.block} key={i}>
              <i id={`fqa-${i}`} className="g-link-tag"></i>
              <h4>{v.title}</h4>
              <ul>
                {v.content.map(item => {
                  if (/[.*](.+)/.test(item)) return <li>{mdParser(item)}</li>

                  return <li>{item}</li>
                })}
              </ul>
            </section>
          ))}
        </div>
      </div>
    </Layout>
  )
}

export default FqaPage

function mdParser(msg) {
  if (typeof msg !== "string") return ""

  let content = msg
    .replace(/\!\[([^\]]*)\]\(([^\)]+)\)/g, (patain, $1, $2, i) => {
      return `<img style="display: block; max-width: 100%; margin: 7px 0;" src="${$2}" alt="${$1}" />`
    })
    .replace(/\[(.*)\]\((.*)\)$/, (patain, $1, $2, i) => {
      return `<a href="${$2}" target="_blank">👉${$1}</a>`
    })

  return <span dangerouslySetInnerHTML={{ __html: content }}></span>
}
